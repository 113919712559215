@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --success: #4bbf73;
  --primary: #2E55E1;
  --warning: #FEBE00;
  --danger: #FF3030;
  --black: #000;
  --white: #ffffff;
  --accent: #070a57;


  --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 75, 191, 115;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-primary-text-emphasis: #052c65;
    --bs-secondary-text-emphasis: #2b2f32;
    --bs-success-text-emphasis: #0a3622;
    --bs-info-text-emphasis: #055160;
    --bs-warning-text-emphasis: #664d03;
    --bs-danger-text-emphasis: #58151c;
    --bs-light-text-emphasis: #495057;
    --bs-dark-text-emphasis: #495057;

  /* ***** Website Theme Variables ***** */
  --primary_bg: #febe00;
  --secondary_bg: #333333;
  --right_container_bg: rgb(255, 255, 255);
  --input_bg: rgba(51, 51, 51, 0.08);
  --search_input_border: rgba(0, 0, 0, 0.2);
  --box_shadow: -3px -3px 9px rgba(0, 0, 0, 0.1), 3px 3px 9px rgba(0, 0, 0, 0.1);
  --box_shadow_light: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --btn_hover_shadow: -3px -3px 7px rgba(0, 0, 0, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.15);
  --table_row_bg: rgba(51, 51, 51, 0.03);

  /* ***** Top Navbar Variables ***** */
  --notification_popover_shadow: -3px -3px 7px rgba(0, 0, 0, 0.1), 3px 3px 7px rgba(0, 0, 0, 0.1);

  /* ***** Sidebar Variables ***** */
  --link_color: rgb(255, 255, 255);
  --active_link_bg: rgb(255, 255, 255);
  --active_link_color: rgb(0, 0, 0);
  --hover_link_bg: rgba(255, 255, 255, 0.1);

  /* ***** Register Page Variables ***** */
  --image_background: #c4c4c4;
  --login_background: #f9f9f9;
  --light_bg_input: #fafafa;

  /* ***** Home Page Variables ***** */
  --card_bg1: rgba(255, 48, 48, 0.07);
  --card_bg2: rgba(254, 190, 0, 0.07);
  --card_bg3: rgba(46, 85, 225, 0.07);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.visibilty-toggle-btn{
  background: #ffffff00;
  border: none;

}

.form-control:focus{
  box-shadow: none !important;
}




.home_card_yellow {
  border: 2px solid var(--danger) !important;
  border-radius: 8px;
  background: var(--card_bg1) !important;
}

.home_card_blue {
  border: 2px solid var(--warning) !important;
  border-radius: 8px;
  background: var(--card_bg2) !important;
}

.home_card_green {
  border: 2px solid var(--info) !important;
  border-radius: 8px;
  background: var(--card_bg3) !important;
}

.home_chart {
  border-width: 3px;
  border-radius: 8px;
}

.icon_yellow {
  background: var(--danger) !important;
  color: var(--white);
  border-radius: 4px;
  padding: 4px 6px;
}

.home_card .card {
  width: auto;
}

.icon_blue {
  background: var(--warning) !important;
  color: var(--white);
  border-radius: 4px;
  padding: 4px 6px;
}

.icon_green {
  background: var(--info) !important;
  color: var(--white);
  border-radius: 4px;
  padding: 4px 6px;
}

.sub_text {
  color: var(--black);
}

.main_container{
  height:100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0px 0px 70px 30px;
}


.btn-white {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffffff !important;
  --bs-btn-border-color: #ffffff !important;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}


/* width */
::-webkit-scrollbar {
  width: 4px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c0c0;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 20px;
}

#root{
  height: 100%;
  width: 100%;
  overflow: hidden;
}


/* Heights */
.h-1 {
  height: 1% !important;
}

.h-2 {
  height: 2% !important;
}

.h-3 {
  height: 3% !important;
}

.h-4 {
  height: 4% !important;
}

.h-5 {
  height: 5% !important;
}

.h-6 {
  height: 6% !important;
}

.h-7 {
  height: 7% !important;
}

.h-8 {
  height: 8% !important;
}

.h-9 {
  height: 9% !important;
}

.h-10 {
  height: 10% !important;
}

.h-11 {
  height: 11% !important;
}

.h-12 {
  height: 12% !important;
}

.h-13 {
  height: 13% !important;
}

.h-14 {
  height: 14% !important;
}

.h-15 {
  height: 15% !important;
}

.h-16 {
  height: 16% !important;
}

.h-17 {
  height: 17% !important;
}

.h-18 {
  height: 18% !important;
}

.h-19 {
  height: 19% !important;
}

.h-20 {
  height: 20% !important;
}

.h-21 {
  height: 21% !important;
}

.h-22 {
  height: 22% !important;
}

.h-23 {
  height: 23% !important;
}

.h-24 {
  height: 24% !important;
}

.h-25 {
  height: 25% !important;
}

.h-26 {
  height: 26% !important;
}

.h-27 {
  height: 27% !important;
}

.h-28 {
  height: 28% !important;
}

.h-29 {
  height: 29% !important;
}

.h-30 {
  height: 30% !important;
}

.h-31 {
  height: 31% !important;
}

.h-32 {
  height: 32% !important;
}

.h-33 {
  height: 33% !important;
}

.h-34 {
  height: 34% !important;
}

.h-35 {
  height: 35% !important;
}

.h-36 {
  height: 36% !important;
}

.h-37 {
  height: 37% !important;
}

.h-38 {
  height: 38% !important;
}

.h-39 {
  height: 39% !important;
}

.h-40 {
  height: 40% !important;
}

.h-41 {
  height: 41% !important;
}

.h-42 {
  height: 42% !important;
}

.h-43 {
  height: 43% !important;
}

.h-44 {
  height: 44% !important;
}

.h-45 {
  height: 45% !important;
}

.h-46 {
  height: 46% !important;
}

.h-47 {
  height: 47% !important;
}

.h-48 {
  height: 48% !important;
}

.h-49 {
  height: 49% !important;
}

.h-50 {
  height: 50% !important;
}

.h-51 {
  height: 51% !important;
}

.h-52 {
  height: 52% !important;
}

.h-53 {
  height: 53% !important;
}

.h-54 {
  height: 54% !important;
}

.h-55 {
  height: 55% !important;
}

.h-56 {
  height: 56% !important;
}

.h-57 {
  height: 57% !important;
}

.h-58 {
  height: 58% !important;
}

.h-59 {
  height: 59% !important;
}

.h-60 {
  height: 60% !important;
}

.h-61 {
  height: 61% !important;
}

.h-62 {
  height: 62% !important;
}

.h-63 {
  height: 63% !important;
}

.h-64 {
  height: 64% !important;
}

.h-65 {
  height: 65% !important;
}

.h-66 {
  height: 66% !important;
}

.h-67 {
  height: 67% !important;
}

.h-68 {
  height: 68% !important;
}

.h-69 {
  height: 69% !important;
}

.h-70 {
  height: 70% !important;
}

.h-71 {
  height: 71% !important;
}

.h-72 {
  height: 72% !important;
}

.h-73 {
  height: 73% !important;
}

.h-74 {
  height: 74% !important;
}

.h-75 {
  height: 75% !important;
}

.h-76 {
  height: 76% !important;
}

.h-77 {
  height: 77% !important;
}

.h-78 {
  height: 78% !important;
}

.h-79 {
  height: 79% !important;
}

.h-80 {
  height: 80% !important;
}

.h-81 {
  height: 81% !important;
}

.h-82 {
  height: 82% !important;
}

.h-83 {
  height: 83% !important;
}

.h-84 {
  height: 84% !important;
}

.h-85 {
  height: 85% !important;
}

.h-86 {
  height: 86% !important;
}

.h-87 {
  height: 87% !important;
}

.h-88 {
  height: 88% !important;
}

.h-89 {
  height: 89% !important;
}

.h-90 {
  height: 90% !important;
}

.h-91 {
  height: 91% !important;
}

.h-92 {
  height: 92% !important;
}

.h-93 {
  height: 93% !important;
}

.h-94 {
  height: 94% !important;
}

.h-95 {
  height: 95% !important;
}

.h-96 {
  height: 96% !important;
}

.h-97 {
  height: 97% !important;
}

.h-98 {
  height: 98% !important;
}

.h-99 {
  height: 99% !important;
}

.h-100 {
  height: 100% !important;
}


/* Widths */
.w-1 {
  width: 1% !important;
}

.w-2 {
  width: 2% !important;
}

.w-3 {
  width: 3% !important;
}

.w-4 {
  width: 4% !important;
}

.w-5 {
  width: 5% !important;
}

.w-6 {
  width: 6% !important;
}

.w-7 {
  width: 7% !important;
}

.w-8 {
  width: 8% !important;
}

.w-9 {
  width: 9% !important;
}

.w-10 {
  width: 10% !important;
}

.w-11 {
  width: 11% !important;
}

.w-12 {
  width: 12% !important;
}

.w-13 {
  width: 13% !important;
}

.w-14 {
  width: 14% !important;
}

.w-15 {
  width: 15% !important;
}

.w-16 {
  width: 16% !important;
}

.w-17 {
  width: 17% !important;
}

.w-18 {
  width: 18% !important;
}

.w-19 {
  width: 19% !important;
}

.w-20 {
  width: 20% !important;
}

.w-21 {
  width: 21% !important;
}

.w-22 {
  width: 22% !important;
}

.w-23 {
  width: 23% !important;
}

.w-24 {
  width: 24% !important;
}

.w-25 {
  width: 25% !important;
}

.w-26 {
  width: 26% !important;
}

.w-27 {
  width: 27% !important;
}

.w-28 {
  width: 28% !important;
}

.w-29 {
  width: 29% !important;
}

.w-30 {
  width: 30% !important;
}

.w-31 {
  width: 31% !important;
}

.w-32 {
  width: 32% !important;
}

.w-33 {
  width: 33% !important;
}

.w-34 {
  width: 34% !important;
}

.w-35 {
  width: 35% !important;
}

.w-36 {
  width: 36% !important;
}

.w-37 {
  width: 37% !important;
}

.w-38 {
  width: 38% !important;
}

.w-39 {
  width: 39% !important;
}

.w-40 {
  width: 40% !important;
}

.w-41 {
  width: 41% !important;
}

.w-42 {
  width: 42% !important;
}

.w-43 {
  width: 43% !important;
}

.w-44 {
  width: 44% !important;
}

.w-45 {
  width: 45% !important;
}

.w-46 {
  width: 46% !important;
}

.w-47 {
  width: 47% !important;
}

.w-48 {
  width: 48% !important;
}

.w-49 {
  width: 49% !important;
}

.w-50 {
  width: 50% !important;
}

.w-51 {
  width: 51% !important;
}

.w-52 {
  width: 52% !important;
}

.w-53 {
  width: 53% !important;
}

.w-54 {
  width: 54% !important;
}

.w-55 {
  width: 55% !important;
}

.w-56 {
  width: 56% !important;
}

.w-57 {
  width: 57% !important;
}

.w-58 {
  width: 58% !important;
}

.w-59 {
  width: 59% !important;
}

.w-60 {
  width: 60% !important;
}

.w-61 {
  width: 61% !important;
}

.w-62 {
  width: 62% !important;
}

.w-63 {
  width: 63% !important;
}

.w-64 {
  width: 64% !important;
}

.w-65 {
  width: 65% !important;
}

.w-66 {
  width: 66% !important;
}

.w-67 {
  width: 67% !important;
}

.w-68 {
  width: 68% !important;
}

.w-69 {
  width: 69% !important;
}

.w-70 {
  width: 70% !important;
}

.w-71 {
  width: 71% !important;
}

.w-72 {
  width: 72% !important;
}

.w-73 {
  width: 73% !important;
}

.w-74 {
  width: 74% !important;
}

.w-75 {
  width: 75% !important;
}

.w-76 {
  width: 76% !important;
}

.w-77 {
  width: 77% !important;
}

.w-78 {
  width: 78% !important;
}

.w-79 {
  width: 79% !important;
}

.w-80 {
  width: 80% !important;
}

.w-81 {
  width: 81% !important;
}

.w-82 {
  width: 82% !important;
}

.w-83 {
  width: 83% !important;
}

.w-84 {
  width: 84% !important;
}

.w-85 {
  width: 85% !important;
}

.w-86 {
  width: 86% !important;
}

.w-87 {
  width: 87% !important;
}

.w-88 {
  width: 88% !important;
}

.w-89 {
  width: 89% !important;
}

.w-90 {
  width: 90% !important;
}

.w-91 {
  width: 91% !important;
}

.w-92 {
  width: 92% !important;
}

.w-93 {
  width: 93% !important;
}

.w-94 {
  width: 94% !important;
}

.w-95 {
  width: 95% !important;
}

.w-96 {
  width: 96% !important;
}

.w-97 {
  width: 97% !important;
}

.w-98 {
  width: 98% !important;
}

.w-99 {
  width: 99% !important;
}

.w-100 {
  width: 100% !important;
}


.fs-10 {
  font-size: 0.6rem !important;
}

.fs-9 {
  font-size: 0.8rem !important;
}

.fs-8 {
  font-size: 1rem !important;
}

.fs-7 {
  font-size: 1.2rem !important;
}

.fs-6 {
  font-size: 1.4rem !important;
}

.fs-5 {
  font-size: 1.6rem !important;
}

.fs-4 {
  font-size: 1.8rem !important;
}

.fs-3 {
  font-size: 2rem !important;
}

.fs-2 {
  font-size: 2.2rem !important;
}

.fs-1 {
  font-size: 2.4rem !important;
}


/* Padding */
.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

/* Horizontal Padding */
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-7 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-9 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

/* Vertical Padding */
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

/* Left Padding */
.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-9 {
  padding-left: 2.25rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

/* Right Padding */
.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-9 {
  padding-right: 2.25rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

/* Bottom Padding */
.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

/* Top Padding */
.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}


/* Margin */
.m-1 { margin: 0.25rem !important; }
.m-2 { margin: 0.5rem !important; }
.m-3 { margin: 0.75rem !important; }
.m-4 { margin: 1rem !important; }
.m-5 { margin: 1.25rem !important; }
.m-6 { margin: 1.5rem !important; }
.m-7 { margin: 1.75rem !important; }
.m-8 { margin: 2rem !important; }
.m-9 { margin: 2.25rem !important; }
.m-10 { margin: 2.5rem !important; }

/* Horizontal margin */
.mx-1 { margin-left: 0.25rem !important; margin-right: 0.25rem !important; }
.mx-2 { margin-left: 0.5rem !important; margin-right: 0.5rem !important; }
.mx-3 { margin-left: 0.75rem !important; margin-right: 0.75rem !important; }
.mx-4 { margin-left: 1rem !important; margin-right: 1rem !important; }
.mx-5 { margin-left: 1.25rem !important; margin-right: 1.25rem !important; }
.mx-6 { margin-left: 1.5rem !important; margin-right: 1.5rem !important; }
.mx-7 { margin-left: 1.75rem !important; margin-right: 1.75rem !important; }
.mx-8 { margin-left: 2rem !important; margin-right: 2rem !important; }
.mx-9 { margin-left: 2.25rem !important; margin-right: 2.25rem !important; }
.mx-10 { margin-left: 2.5rem !important; margin-right: 2.5rem !important; }

/* Vertical margin */
.my-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
.my-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
.my-3 { margin-top: 0.75rem !important; margin-bottom: 0.75rem !important; }
.my-4 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
.my-5 { margin-top: 1.25rem !important; margin-bottom: 1.25rem !important; }
.my-6 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
.my-7 { margin-top: 1.75rem !important; margin-bottom: 1.75rem !important; }
.my-8 { margin-top: 2rem !important; margin-bottom: 2rem !important; }
.my-9 { margin-top: 2.25rem !important; margin-bottom: 2.25rem !important; }
.my-10 { margin-top: 2.5rem !important; margin-bottom: 2.5rem !important; }

/* Left margin */
.ms-1 { margin-left: 0.25rem !important; }
.ms-2 { margin-left: 0.5rem !important; }
.ms-3 { margin-left: 0.75rem !important; }
.ms-4 { margin-left: 1rem !important; }
.ms-5 { margin-left: 1.25rem !important; }
.ms-6 { margin-left: 1.5rem !important; }
.ms-7 { margin-left: 1.75rem !important; }
.ms-8 { margin-left: 2rem !important; }
.ms-9 { margin-left: 2.25rem !important; }
.ms-10 { margin-left: 2.5rem !important; }

/* Right margin */
.me-1 { margin-right: 0.25rem !important; }
.me-2 { margin-right: 0.5rem !important; }
.me-3 { margin-right: 0.75rem !important; }
.me-4 { margin-right: 1rem !important; }
.me-5 { margin-right: 1.25rem !important; }
.me-6 { margin-right: 1.5rem !important; }
.me-7 { margin-right: 1.75rem !important; }
.me-8 { margin-right: 2rem !important; }
.me-9 { margin-right: 2.25rem !important; }
.me-10 { margin-right: 2.5rem !important; }

/* Bottom margin */
.mb-1 { margin-bottom: 0.25rem !important; }
.mb-2 { margin-bottom: 0.5rem !important; }
.mb-3 { margin-bottom: 0.75rem !important; }
.mb-4 { margin-bottom: 1rem !important; }
.mb-5 { margin-bottom: 1.25rem !important; }
.mb-6 { margin-bottom: 1.5rem !important; }
.mb-7 { margin-bottom: 1.75rem !important; }
.mb-8 { margin-bottom: 2rem !important; }
.mb-9 { margin-bottom: 2.25rem !important; }
.mb-10 { margin-bottom: 2.5rem !important; }

/* Top margin */
.mt-1 { margin-top: 0.25rem !important; }
.mt-2 { margin-top: 0.5rem !important; }
.mt-3 { margin-top: 0.75rem !important; }
.mt-4 { margin-top: 1rem !important; }
.mt-5 { margin-top: 1.25rem !important; }
.mt-6 { margin-top: 1.5rem !important; }
.mt-7 { margin-top: 1.75rem !important; }
.mt-8 { margin-top: 2rem !important; }
.mt-9 { margin-top: 2.25rem !important; }
.mt-10 { margin-top: 2.5rem !important; }



.btn-shadow:hover {
  /* Use CSS variables (custom properties) to store button color */
  --button-color: inherit;
  /* Use the button's current color */

  /* Adjust the shadow color and properties as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-color: var(--button-color);
  border-color: var(--button-color);
}


 /* .dropdown-toggle::after {
  display: none !important;
  content: none !important;
} */

.hide-arrow.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content:none !important;
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}
.slick-slide > div{
  height: 100% !important;
}

/* Custom styles for the success button */
/* .btn-success {
  color: #fff !important;
  background-color: #4bbf73 !important;
  border-color: #4bbf73 !important;
}

.btn-success:hover {
  color: #fff !important;
  background-color: #3ca663 !important;
  border-color: #389f5c !important;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff !important;
  background-color: #3ca663 !important;
  border-color: #389f5c !important;
  box-shadow: 0 0 0 0.25rem rgba(75, 191, 115, 0.5) !important;
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff !important;
  background-color: #4bbf73 !important;
  border-color: #4bbf73 !important;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: #fff !important;
  background-color: #389f5c !important;
  border-color: #369456 !important;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(75, 191, 115, 0.5) !important;
} */


/* Custom styles for the danger button */
/* .btn-danger {
  color: #fff !important;
  background-color: #FF3030 !important;
  border-color: #FF3030 !important;
}

.btn-danger:hover {
  color: #fff !important;
  background-color: #E52727 !important;
  border-color: #D82222 !important;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff !important;
  background-color: #E52727 !important;
  border-color: #D82222 !important;
  box-shadow: 0 0 0 0.25rem rgba(255, 48, 48, 0.5) !important;
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff !important;
  background-color: #FF3030 !important;
  border-color: #FF3030 !important;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #fff !important;
  background-color: #D82222 !important;
  border-color: #C11E1E !important;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 48, 48, 0.5) !important;
} */


/* Custom styles for the primary button */
/* .btn-primary {
  color: #fff !important;
  background-color: #2E55E1 !important;
  border-color: #2E55E1 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #294ED6 !important;
  border-color: #2546C9 !important;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff !important;
  background-color: #294ED6 !important;
  border-color: #2546C9 !important;
  box-shadow: 0 0 0 0.25rem rgba(46, 85, 225, 0.5) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff !important;
  background-color: #2E55E1 !important;
  border-color: #2E55E1 !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #2546C9 !important;
  border-color: #213FBA !important;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(46, 85, 225, 0.5) !important;
} */

/* Custom styles for the warning button */
/* .btn-warning {
  color: #212529 !important;
  background-color: #FEBE00 !important;
  border-color: #FEBE00 !important;
}

.btn-warning:hover {
  color: #212529 !important;
  background-color: #E9B100 !important;
  border-color: #D4A100 !important;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #212529 !important;
  background-color: #E9B100 !important;
  border-color: #D4A100 !important;
  box-shadow: 0 0 0 0.25rem rgba(254, 190, 0, 0.5) !important;
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529 !important;
  background-color: #FEBE00 !important;
  border-color: #FEBE00 !important;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
  color: #212529 !important;
  background-color: #D4A100 !important;
  border-color: #C39A00 !important;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(254, 190, 0, 0.5) !important;
} */

/* Custom text color classes with !important */
/* .text-primary {
  color: var(--primary) !important;
}

.text-success {
  color: var(--success) !important;
}

.text-warning {
  color: var(--warning) !important;
}

.text-danger {
  color: var(--danger) !important; 
}
*/

/* Custom background color classes with !important */
/* .bg-primary {
  background-color: var(--primary) !important;
}

.bg-success {
  background-color: var(--success) !important;
}

.bg-warning {
  background-color: var(--warning) !important;
}

.bg-danger {
  background-color: var(--danger) !important;
} */

/* Generate rotate classes from 0 to 360 degrees in increments of 10 degrees */
.rotate-0 {
  transform: rotate(0deg);
}

.rotate-10 {
  transform: rotate(10deg);
}

.rotate-20 {
  transform: rotate(20deg);
}

.rotate-30 {
  transform: rotate(30deg);
}

.rotate-40 {
  transform: rotate(40deg);
}

.rotate-50 {
  transform: rotate(50deg);
}

.rotate-60 {
  transform: rotate(60deg);
}

.rotate-70 {
  transform: rotate(70deg);
}

.rotate-80 {
  transform: rotate(80deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-100 {
  transform: rotate(100deg);
}

.rotate-110 {
  transform: rotate(110deg);
}

.rotate-120 {
  transform: rotate(120deg);
}

.rotate-130 {
  transform: rotate(130deg);
}

.rotate-140 {
  transform: rotate(140deg);
}

.rotate-150 {
  transform: rotate(150deg);
}

.rotate-160 {
  transform: rotate(160deg);
}

.rotate-170 {
  transform: rotate(170deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-190 {
  transform: rotate(190deg);
}

.rotate-200 {
  transform: rotate(200deg);
}

.rotate-210 {
  transform: rotate(210deg);
}

.rotate-220 {
  transform: rotate(220deg);
}

.rotate-230 {
  transform: rotate(230deg);
}

.rotate-240 {
  transform: rotate(240deg);
}

.rotate-250 {
  transform: rotate(250deg);
}

.rotate-260 {
  transform: rotate(260deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate-280 {
  transform: rotate(280deg);
}

.rotate-290 {
  transform: rotate(290deg);
}

.rotate-300 {
  transform: rotate(300deg);
}

.rotate-310 {
  transform: rotate(310deg);
}

.rotate-320 {
  transform: rotate(320deg);
}

.rotate-330 {
  transform: rotate(330deg);
}

.rotate-340 {
  transform: rotate(340deg);
}

.rotate-350 {
  transform: rotate(350deg);
}

.rotate-360 {
  transform: rotate(360deg);
}

/* Add other transition properties if needed */


.ribbon {
  position: absolute;
  top:-3px;
  left: -20px;
  text-align: center;
}



.bg-accent{
  background-color: #070a57 !important;
}

.fs-7{
  font-size: 0.8rem !important;
}
.fs-8{
  font-size: 0.6rem !important;
}

.text-justify{
  text-align: justify;
}

.underlined-text {
  display: inline-block;
  position: relative;
  text-decoration: none;
  /* Remove default text underline */
  color: #000;
  /* Set the text color as needed */
}



.underlined-text::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 2px;
  /* Adjust the height to control the underline thickness */
  background-color: var(--accent);
  /* Adjust the color as needed */
  bottom: -4px;
  /* Adjust this value to control the space between text and underline */
  left: 10%;
  /* Adjust this value to center the underline */
  transform: scaleX(1);
  /* Expand the underline width */
  transform-origin: bottom center;
  /* Center the transform origin */
  transition: transform 0.2s ease-in-out;
  /* Add a transition for smooth animation */
}


.timeline {
  border-left: 2px solid rgb(var(--bs-primary-rgb), 0.5);
  position: relative;
  list-style: none;
}

.timeline .timeline-item {
  position: relative;
}

.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .timeline-item:after {
  background-color: var(--bs-primary);
  left: -38px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
}


.line {
  width: 120px;
  height: 5px;
  margin: 30px auto 0;
  border-radius: 6px;
  background: #2d2ed4;
  /* background: -webkit-linear-gradient(to right, #e24997, #2d2ed4); */
  /* background: linear-gradient(to right, #e24997, #2d2ed4); */
}


.remove-after::after {
  display: none !important;
}

.a4-page {
  width: 210mm  !important;
  height: max-content !important;
}

.dashed-line{
      border-bottom: 2px rgb(220, 220, 220) dashed;
      width: 100%;
}

.vh-75{
  height: 75vh !important;
}
.bg-seprator{
  background-color: #7e7e7e;
}




.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  border-radius: inherit;
}

.vh-25{
  height: 25vh;
}



.card_heading{
  font-size: 1.15rem;
  font-weight: 700;
}
.section_heading{
  font-size: 1.5rem;
  font-weight: 700;
}
.label{
  font-size: 1rem;
  font-weight: 600;
}


.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--bs-nav-link-hover-color) !important ;
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-link:focus,
.nav-link:hover {
  color: var(--bs-nav-link-hover-color) !important ;
}
.nav-link{
  color: var(--bs-nav-tabs-link-active-color) !important;
}

.bg-faded-info{
  background-color: #e7f5ff;
}
.bg-faded-danger{
  background-color: #ecd1d5;
}
.bg-faded-primary{
  background-color: #cfe2ff;
}
.bg-faded-success{
  background-color: #b8ecd4;
}
.bg-faded-warning{
  background-color: #fff3cd;
}


.react-tel-input {
  font-size: 0.9rem !important;
}

.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  letter-spacing: .01rem;
  border-radius: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  font-size: 0.9rem !important;
}


.content-none{
  content:none !important;
  
}
.content-none::before{
  content:none !important;
  
}
.content-none::after{
  content:none !important;
  
}

.div_to_show {
  display: none;
  z-index: 9999;
}

.hover_to_show:hover .div_to_show {
  display: block;
}

.bg-light-danger{
  background-color: var(--danger);
}

svg{
  display: initial;
}