.sidebar_outer_container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    font-weight: 500;
}
.sidebar_left {
    width: 15%;
    background-color: var(--accent);
    color: var(--link_color);
    padding: 20px 0;
}

.sidbar_logo {
    width: 60%;
    margin: 0 20% 30px 20%;
    height: 80px;
}
.sidbar_logo img {
    height: auto;
    width:100%;
}

.link_options {
    color: var(--link_color);
    padding: 12px 0 12px 15px;
    width: 100%;
    border-radius: 20px 0px 0px 20px;
    transition: all 0.2s linear;
    transform-origin: left;
    background: linear-gradient(to right, transparent 50%, var(--hover_link_bg) 50%) left;
    background-size: 200%;
    text-decoration: none;
}
.menu_links {
    padding: 5px 0;
    margin-left: 6%;
    border-radius: 20px 0px 0px 20px;
    list-style: none;
}

.link_options.active {
    color: var(--active_link_color);
    background-color: var(--active_link_bg);
    border:var(--active_link_bg);
}
.link_options:hover:not(.active) {
    background-position: right;
    color: var(--link_color);
}

/* right side screen */
.sidebar_right_container {
    width: 100%;
    background-color: var(--accent);
    padding: 10px 10px 10px 0;
}

.sidebar_right_inner_container {
    background-color: var(--right_container_bg);
    border-radius: 20px;
    width: 100%;
    height: 100%;
}

.sidebar_right_inner_container{
    overflow-y: auto;
}

@media (max-width: 992px) {
    .sidebar_right_inner_container {
    border-radius: 0px !important;
    }
}
