@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

/* body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #ccc;
} */

.background-green {
    position: absolute;
    top: 0;
    width: 100%;
    height: 20%;
    background-color: #009688;
}

.main-container {
    position: relative;
    width: 1000px;
    max-width: 100%;
    height: calc(100vh - 40px);
    background: #fff;
    display: flex;
    box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.5), 0px 2px 5px 0 rgba(0, 0, 0, 0.6);
}

.main-container .left-container {
    position: relative;
    width: 30%;
    height: 100%;
    flex: 30%;
    background: #fff;
}
 .right-container {
    position: relative;
    width: 70%;
    height: 100%;
    flex: 70%;
    background: #e5ddd5;
}
.right-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(https://camo.githubusercontent.com/854a93c27d64274c4f8f5a0b6ec36ee1d053cfcd934eac6c63bed9eaef9764bd/68747470733a2f2f7765622e77686174736170702e636f6d2f696d672f62672d636861742d74696c652d6461726b5f61346265353132653731393562366237333364393131306234303866303735642e706e67);
    opacity: 0.5;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    background: #ededed;
    padding: 0 15px;
}

.user-img {
    position: relative;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
}

.dp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.nav-icons {
    display: flex;
    justify-content: flex-end;
    padding-left: 110px;
}

.nav-icons li {
    backgroud-color: pink;
    list-style: none;
    display: flex;
    cursor: pointer;
    color: #51585c;
    margin-left: 22px;
    font-size: 18px;
}

.notif-box {
    position: relative;
    display: flex;
    width: 100%;
    height: 70px;
    background: #76daff;
    align-items: center;
    font-size: 0.8em;
    text-decoration: none;
}

.notif-box i {
    position: relative;
    left: 13px;
    background: #fff;
    padding: 10px;
    width: 42px;
    height: auto;
    font-size: 20px;
    border-radius: 55%;
    cursor: pointer;
    color: #76daff;
}

.notif-box .fa-xmark {
    position: absolute;
    left: 260px;
    text-align: center;
    background: #76daff;
    color: #fff;
}

.notif-text {
    margin: 25px;
}

.notif-text a {
    text-decoration: none;
    color: #333;
    font-size: 0.9em;
}

.search-container {
    position: relative;
    width: 100%;
    height: 40px;
    background: #f6f6f6;
    display: flex;
    /*   justify-content: center; */
    align-items: center;
}

.search-container .input input {
    width: 121%;
    outline: none;
    border: none;
    background: #fff;
    padding: 5px;
    height: 30x;
    border-radius: 10px;
    font-size: 12px;
    padding-left: 60px;
    margin: 10px
}

.search-container .input i {
    position: absolute;
    left: 26px;
    top: 14px;
    color: #bbb;
    font-size: 0.8em;
}

.chat-list {
    position: relative;
    height: calc(100% - 170px);
    overflow-y: auto;
}

.chat-list .chat-box {
    position: relative;
    width: 100%;
    display: flex;
    /*   justify-content: center; */
    align-items: center;
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid #eee;
}

.chat-list .chat-box .img-box {
    position: relative;
    width: 55px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
}

.chat-list .chat-box .chat-details {
    width: 100%;
    margin-left: 10px;
}

.chat-list .chat-box .chat-details .text-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
}

.chat-list .chat-box .chat-details .text-head h4 {
    font-size: 1.1em;
    font-weight: 600;
    color: #000;
}

.chat-list .chat-box .chat-details .text-head .time {
    font-size: 0.8em;
    color: #aaa;
}

.chat-list .chat-box .chat-details .text-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-list .chat-box .chat-details .text-message p {
    color: #aaa;
    font-size: 0.9em;
    overlay: hidden;
}

img {
    width: 100%;
    object-fit: cover;
}

.chat-list .chat-box .chat-details .text-message b {
    background: #06e744;
    color: #fff;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    /*   text-align: center; */
    font-size: 0.8em;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-list .active {
    background: #ebebeb;
}

.chat-list .chat-box:hover {
    background: #f5f5f5;
}

.chat-list .chat-box .chat-details .text-head .unread {
    color: #06e744;
}


/* right-container */


.right-container .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right-container .header .img-text .user-img .dp {
    position: relative;
    top: -2px;
    left: 0px;
    width: 40px;
    height: auto;
    overflow: hidden;
    object-fit: cover;
}

.right-container .header .img-text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.right-container .header .img-text h4 {
    font-weight: 500;
    line-height: 1.2em;
    margin-left: 15px;
}

.right-container .header .img-text h4 span {
    font-size: 0.8em;
    color: #555;
}

.right-container .header .nav-icons {
    position: relative;
    margin-right: 0px;
    /*   padding: 5px; */
}

.right-container .header .nav-icons i {
    padding: 10px;
}

.chat-container {
    position: relative;
    width: 100%;
    height: calc(100% - 120px);
    /*60+60*/
    padding: 50px;
    overflow-y: auto;
}

.message-box {
    position: relative;
    display: flex;
    width: 100%;
    margin: 5px 0;
}

.message-box p {
    position: relative;
    right: 0;
    text-align: right;
    max-width: 65%;
    padding: 12px;
    background: #dcf8c6;
    border-radius: 10px;
    font-size: 0.9em;
}

.message-box.my-message p::before {
    content: '';
    position: absolute;
    top: 0;
    right: -12px;
    width: 20px;
    height: 20px;
    background: linear-gradient(135deg, #dcf8c6 0%, #dcf8c6 50%, transparent 50%, transparent);
}

.message-box p span {
    display: block;
    margin-top: 5px;
    font-size: 0.8em;
    opacity: 0.5;
}

.my-message {
    justify-content: flex-end;
}

.friend-message p {
    background: #fff;
}

.friend-message {
    justify-content: flex-start;

}

.chat-container .my-message i {
    color: yellow;
}

.message-box.friend-message::before {
    content: '';
    position: absolute;
    top: 0;
    left: -12px;
    width: 20px;
    height: 20px;
    background: linear-gradient(225deg, #fff 0%, #fff 50%, transparent 50%, transparent);
}

.chatbox-input {
    position: relative;
    width: 100%;
    height: 60px;
    background: #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chatbox-input i {
    cursor: pointer;
    font-size: 1.8em;
    color: #515855;
}

.chatbox-input i:nth-child(1) {
    margin: 15px;
}

.chatbox-input i:last-child {
    margin-right: 25px;
}

.chatbox-input input {
    position: relative;
    width: 90%;
    margin: 0 20px;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1em;
    border: none;
    outline: none;
}

/* Remove fit-content and set full-width for the slides */
/* .slick-track {
    display: flex;
    width: 100% !important;
} */

.slick-slide {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.slick-list {
    overflow: hidden;
}