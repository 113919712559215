.sidebar_outer_container {
    height: 100dvh;
    font-weight: 500;
}
.sidebar_left {
    width: 15%;
    background-color: var(--accent);
    color: var(--link_color);
    padding: 20px 0;
}

.sidbar_logo {
    width: 60%;
    margin: 0 20% 30px 20%;
    height: 80px;
}
.sidbar_logo img {
    height: auto;
    width:100%;
}

.link_options {
    color: var(--link_color);
    padding: 12px 0 12px 15px;
    width: 100%;
    border-radius: 20px 0px 0px 20px;
    transition: all 0.2s linear;
    transform-origin: left;
    background: linear-gradient(to right, transparent 50%, var(--hover_link_bg) 50%) left;
    background-size: 200%;
    text-decoration: none;
}
.menu_links {
    padding: 5px 0;
    margin-left: 6%;
    border-radius: 20px 0px 0px 20px;
    list-style: none;
}

.link_options.active {
    color: var(--active_link_color);
    background-color: var(--active_link_bg);
}
.link_options:hover:not(.active) {
    background-position: right;
    color: var(--link_color);
}

/* right side screen */
.sidebar_right_container {
    width: 85%;
    background-color: var(--accent);
    padding: 10px 10px 10px 0;
    overflow: hidden;
}

.sidebar_right_inner_container {
    background-color: var(--right_container_bg);
    border-radius: 20px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.right_inner_screen{
    height:89%;
    overflow-y: scroll;
    overflow-x: hidden;
}
.right_screen{
    height:100%;
    overflow: hidden;
}

@media (max-width: 992px) {
    .sidebar_right_inner_container {
    border-radius: 0px !important;
    }
        .sidebar_right_container {
            width: 100%;
            background-color: var(--secondary_bg);
            padding: 10px 10px 10px 0;
        }
}


.miniature-window {
    width: 500px;
    height: 300px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 12px;
    overflow: auto;
}

/* From Uiverse.io by ahmed150up */
.chat-card {
    width: 500px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.chat-header {
    padding: 10px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
}

.chat-header .h2 {
    font-size: 16px;
    color: #333;
}

.chat-body {
    padding: 20px;
}

.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
}

.incoming {
    background-color: #e1e1e1;
}

.outgoing {
    background-color: #f2f2f2;
    text-align: right;
}

.message p {
    font-size: 14px;
    color: #333;
    margin: 0;
}

.chat-footer {
    padding: 10px;
    background-color: #f2f2f2;
    display: flex;
}

.chat-footer input[type="text"] {
    flex-grow: 1;
    padding: 5px;
    border: none;
    border-radius: 3px;
}

.chat-footer button {
    padding: 5px 10px;
    border: none;
    background-color: #4285f4;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.chat-footer button:hover {
    background-color: #0f9d58;
}

@keyframes chatAnimation {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.chat-card .message {
    animation: chatAnimation 0.3s ease-in-out;
    animation-fill-mode: both;
    animation-delay: 0.1s;
}

.chat-card .message:nth-child(even) {
    animation-delay: 0.2s;
}

.chat-card .message:nth-child(odd) {
    animation-delay: 0.3s;
}
