.timeline {
    border-left: 2px solid rgb(var(--bs-primary-rgb),0.5);
    position: relative;
    list-style: none;
}

.timeline .timeline-item {
    position: relative;
}

.timeline .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline .timeline-item:after {
    background-color: var(--bs-primary);
    left: -38px;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
}